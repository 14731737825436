import { LanguageToggle } from '@/components/layout/language-toggle';
import { ThemeToggle } from '@/components/layout/theme-toggle';
import { useUser } from '@/hooks/use-auth';
import { useLocale } from '@/hooks/use-language';
import * as React from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import logo from '@/assets/img/redgo_logo.svg';

export function AuthLayout() {
  const user = useUser();
  const navigate = useNavigate();
  const { lang } = useLocale();
  const params = useParams();
  const location = useLocation();

  React.useEffect(() => {
    if (user) {
      navigate(`/${lang}/case-history`);
    } else if (params.lang !== lang) {
      const path = location.pathname.split('/');
      const searchParams = location.search;
      const [, , ...pathEnd] = path;
      navigate(
        searchParams.length
          ? `/${lang}/${pathEnd
              .filter((end) => end !== '')
              .join('/')}${searchParams}`
          : `/${lang}/${pathEnd.join('/')}`
      );
    }
  }, [user, navigate, lang, params.lang, location]);

  return (
    <div className="min-h-[550px] h-[calc(100vh-13.25rem)] md:h-[calc(100vh-11rem)]">
      <header className="h-20">
        <div className="flex items-center justify-center mb-2 h-full">
          <img src={logo} alt="Redgo logo" className="h-7"></img>
        </div>
      </header>
      <Outlet />
      <footer className="py-6 md:px-8 md:py-0">
        <div className="container max-w-none flex flex-col items-center h-18 justify-between gap-4 md:h-24 md:flex-row">
          <p className="text-center text-sm leading-loose text-muted-foreground md:text-left">
            {/* here is a placeholder for text if it's wanted to add */}
          </p>
          <div className="flex items-center gap-2">
            <ThemeToggle />
            <LanguageToggle />
          </div>
        </div>
      </footer>
    </div>
  );
}
