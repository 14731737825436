import React from 'react';
import { createId } from '@paralleldrive/cuid2';
import { bus } from './bus.tsx';
import { useUser } from '../hooks/use-auth.tsx';
import mqtt, { MqttClient } from 'mqtt';

export function RealtimeProvider(props: { children: React.ReactElement }) {
  const user = useUser();
  const connectionRef = React.useRef<MqttClient | null>(null);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    const endpoint = import.meta.env.VITE_REALTIME_ENDPOINT;
    const authorizer = import.meta.env.VITE_AUTHORIZER;

    async function createConnection() {
      try {
        console.log('creating new connection');

        if (connectionRef.current) {
          connectionRef.current.end();
        }

        const url = new URL(`wss://${endpoint}/mqtt`);
        url.searchParams.set('x-amz-customauthorizer-name', authorizer);

        const connection = mqtt.connect(url.toString(), {
          protocolVersion: 5,
          manualConnect: true,
          username: '', // this must be empty for the authorizer to work
          password: user!.token,
          clientId: 'client_' + createId(),
        });

        connection.on('connect', async () => {
          console.log('WS connected');
          await connection.subscribeAsync(
            `redgo-order-form/${import.meta.env.VITE_STAGE}/#`,
            { qos: 1 }
          );
        });

        connection.on('error', (e) => {
          console.log('connection error', e);
        });

        connection.on('message', (fullTopic, payload) => {
          const splits = fullTopic.split('/');
          const message = new TextDecoder('utf8').decode(
            new Uint8Array(payload)
          );

          console.log('Got realtime message: ' + message);
          const topic = splits[2];
          if (topic === 'poke') {
            bus.emit('poke', {});
          }
        });

        connection.on('disconnect', console.log);

        connection.connect();

        connectionRef.current = connection;
      } catch (error) {
        console.error('Failed to create connection', error);
      }
    }

    createConnection();

    return () => {
      if (connectionRef.current) {
        connectionRef.current.end();
        connectionRef.current = null;
      }
    };
  }, [user]);

  return props.children;
}
