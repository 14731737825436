import * as React from 'react';
import { DashboardShell } from '@/components/layout/shell';
import { createCaseColumns } from '@/components/table/case/case-columns';
import { CaseTable } from '@/components/table/case/case-table';
import { useLocale } from '@/hooks/use-language';
import { trpc } from '@/lib/trpc';
import { CaseDetailDialog } from './case-detail-dialog';
import { useSearchParams } from 'react-router-dom';
import { CaseStatus } from '@/backend/models/Case';
import { useDebounce } from '@/hooks/use-debounce';
import { useRealtime } from '@/hooks/use-realtime';

export function CaseHistoryPage() {
  const { t } = useLocale(['case-history', 'case']);
  const [searchParams] = useSearchParams();

  const filters = React.useMemo(() => {
    const search = searchParams.get('search');
    const status = searchParams.get('status');

    return {
      registrationPlate: search ?? undefined,
      status: (status as CaseStatus) ?? undefined,
    };
  }, [searchParams]);

  const debouchedSearch = useDebounce(filters.registrationPlate, 500);

  const { data, refetch, isLoading } = trpc.case.getCases.useQuery({
    limit: 300,
    filters: { ...filters, registrationPlate: debouchedSearch },
  });

  const columns = createCaseColumns(t);

  // receive realtime updates for cases
  useRealtime({
    callback: refetch,
  });

  return (
    <DashboardShell title={t('case-history:title')}>
      <div>
        <CaseDetailDialog />
        <CaseTable
          data={data?.items ?? []}
          isLoading={!data?.items && isLoading}
          columns={columns}
        />
      </div>
    </DashboardShell>
  );
}
