import { z } from 'zod';

export const PointType = {
  POINT: 'Point',
} as const;
export type PointType = (typeof PointType)[keyof typeof PointType];

export const LocationType = {
  STREET: 'Street',
  HOME: 'Home',
  PARKING_GARAGE: 'ParkingGarage',
  HIGHWAY: 'Highway',
  STORAGE_DEPOT: 'StorageDepot',
  OTHER: 'Other',
} as const;
export type LocationType = (typeof LocationType)[keyof typeof LocationType];

export const CountrySchema = z.object({
  name: z.string(),
  alfa2: z.string().default(''),
  alfa3: z.string().default(''),
});
export type Country = z.infer<typeof CountrySchema>;

// https://docs.mongodb.com/manual/reference/geojson/#point
const GeoJSONPointSchema = z.object({
  type: z.nativeEnum(PointType),
  /**
   * 0: longitude, 1: latitude
   */
  coordinates: z.tuple([z.number(), z.number()]),
});
export type GeoJSONPoint = z.infer<typeof GeoJSONPointSchema>;

const GeoAddressSchema = z.object({
  label: z.string(),
  country: z.string(),
  state: z.string(),
  county: z.string(),
  city: z.string(),
  district: z.string(),
  street: z.string(),
  postalCode: z.string(),
  houseNumber: z.string(),
});
export type GeoAddress = z.infer<typeof GeoAddressSchema>;

export const GeoLocationSchema = GeoJSONPointSchema.extend({
  address: GeoAddressSchema,
});
export type GeoLocation = z.infer<typeof GeoLocationSchema>;

const GeoPlaceSchema = GeoLocationSchema.extend({
  title: z.string().optional(),
  phone: z.string().optional(),
  email: z.string().optional(),
});
export type GeoPlace = z.infer<typeof GeoPlaceSchema>;
